import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';

import Button from './Button';
import { calcCrop } from './utils';
import { media } from './theme';
import useSiteContext from './SiteContext';
import Image from './Image';
import { useViewport, useElementSize } from './hooks';

const Hero = props => {
  const { heroButton, heroText, heroSubText, heroImage, hasSubNav } = props;

  const { viewport } = useSiteContext();
  const [wrapperRef, wrapperSize, trigger] = useElementSize();

  const { height: heroHeight, objectPosition } = calcCrop(
    heroImage,
    viewport.width
  );
  return (
    <StyledHero
      className="hero"
      ref={wrapperRef}
      viewport={viewport}
      hasSubNav={hasSubNav}
    >
      {/* <HeroImage
        className="hero__image"
        fluid={heroImage.asset.fluid}
        alt={heroImage.alt}
        objectFit="cover"
        objectPosition={objectPosition}
        height={heroHeight}
      /> */}
      <Image
        className="hero__image"
        image={heroImage}
        containerWidth={viewport.width}
        wrapperHeight={wrapperSize.height}
        onLoad={trigger}
      />
      {(heroText || heroSubText || heroButton) && (
        <div className="hero__content">
          {heroText && <h2 className="hero__heading">{heroText}</h2>}
          {heroSubText && (
            <span className="hero__sub-heading">{heroSubText}</span>
          )}
          {heroButton && (
            <Button className="hero__button" href={heroButton.url}>
              {heroButton.label}
            </Button>
          )}
        </div>
      )}
    </StyledHero>
  );
};

const StyledHero = styled.div`
  position: relative;
  height: 400px;
  ${media.break`
    height: ${({ viewport, theme, hasSubNav }) =>
      viewport.height - theme.sizes.header - (hasSubNav ? 63 : 0)}px;
    `}
  overflow: hidden;
  .hero {
    &__image {
      position: absolute;
      ${media.break`
        position: relative;
      `}
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      text-align: center;
      position: relative;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 4rem;
      ${media.break`
        position: absolute;
      `}
    }
    &__heading {
      color: ${({ theme }) => theme.blue};
      text-transform: uppercase;
      font-size: 3.6rem;
      width: ${({ theme }) => theme.sizes.content}px;
      margin: 0;
      max-width: 100%;
      ${media.break`
        font-size: 4.8rem;
      `}
    }
    &__sub-heading {
      color: ${({ theme }) => theme.white};
      text-transform: uppercase;
      font-size: 2.1rem;
      font-weight: ${({ theme }) => theme.font.bold};
      margin-bottom: 2rem;
      font-family: ${({ theme }) => theme.font.heading};
    }
    &__button {
      margin-top: 2rem;
    }
  }
`;

export default Hero;
