import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import HomePage from '../components/HomePage';

const IndexPage = ({ data }) => {
  const { sanityHome } = data;

  return (
    <Wrapper className="home" home>
      <HomePage {...sanityHome} />
    </Wrapper>
  );
};

export const HomeQuery = graphql`
  query homeQuery {
    sanityHome {
      hero {
        heroButton {
          label
          url
        }
        heroText
        heroSubText
        heroImage {
          alt
          asset {
            assetId
            id
            sha1hash
            _id
            _rev
            _type
            fluid(maxWidth: 1600) {
              ...GatsbySanityImageFluid
            }
            metadata {
              lqip
              dimensions {
                width
                height
                aspectRatio
              }
            }
            url
          }
          crop {
            top
            left
            right
            bottom
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      # blocks {
      #   _key
      #   slug {
      #     current
      #   }
      #   link {
      #     label
      #     url
      #   }
      #   heading
      #   # _rawBody(resolveReferences: { maxDepth: 10 })
      #   copy
      #   image {
      #     alt
      #     asset {
      #       fluid(maxWidth: 800) {
      #         ...GatsbySanityImageFluid
      #       }
      #       metadata {
      #         dimensions {
      #           height
      #           width
      #         }
      #       }
      #     }
      #     crop {
      #       top
      #       left
      #       right
      #       bottom
      #     }
      #   }
      # }
    }
  }
`;

export default IndexPage;
