import React from 'react';

import Hero from './Hero';
import Content from './Content';

const HomePage = ({ hero, _rawContent }) => {
  // console.log(blocks);
  return (
    <div className="page-wrapper">
      <Hero {...hero} />
      <Content className="home-content">{_rawContent}</Content>
    </div>
  );
};

export default HomePage;
